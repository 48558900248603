



















































import Vue from 'vue'
import Component from "vue-class-component";
import {vxm} from "@/store";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";


@Component({
    components: {
      WaitModal
    },
})

export default class Home extends Vue {
    private theUi = vxm.ui;
    private auth = vxm.auth;
}
